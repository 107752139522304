import React, {useContext} from 'react';
import { Layout, SEO } from 'components/common';
import {  Intro, Social, Skills } from 'components/about';


const skills = [
  {name: "Backend", per: "87"},
  {name: "Frontend", per: "67"},
  {name: "Machine learning", per: "77"},
  {name: "Cloud", per: "77"},
]

export default () => {
  return (
  <Layout>
    <SEO />
    <Intro />
    {/* <Social /> */}
    <Skills skills={skills}/>
  </Layout>
  );
};

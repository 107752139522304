import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 4rem;
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
`;

export const IntroWrapper = styled.div`
  // padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 22pt;
    color: ${({ theme }) => (theme === 'light' ? '#00e676' : '#ffa000')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 20pt;
    }
  }

  h3 {
    margin-top: 3rem;
    font-size: 20pt;
    font-weight: bold;
    color: ${({ theme }) => (theme === 'light' ? '#373737' : '#e6e6e6')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 16pt;
    }
  }

  p {
    margin-bottom: 1.5rem;
    font-size: 14pt;
    font-weight: normal;
    color: ${({ theme }) => (theme === 'light' ? '#373737' : '#e6e6e6')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 12pt;
    }
  }
`;

export const Contact = styled.div`
margin-left: 1rem;
  p {
    // margin-bottom: 1rem;
    font-size: 14pt;
    font-weight: normal;
    color: ${({ theme }) => (theme === 'light' ? '#373737' : '#e6e6e6')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 12pt;
    }
  }
`;


export const Thumbnail = styled.div`
  flex: 1;
  margin: 1.5rem;
  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    width: 100%;
    margin: auto;
  @media (max-width: 960px) {
    width: 0%;
    height: 0%;
  }
  }
`;

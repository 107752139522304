import styled from 'styled-components';



export const Wrapper = styled.div`
  padding-top: 2rem;
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
  .skills {
    width: 100%;
    max-width: 600px;
    // padding: 0 20px;
  }
`;
export const Skill = styled.div`
  .skill-name {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 20px 0;
  }
  .skill-bar {
    height: 8px;
    width: 50%;
    @media (max-width: 680px) {
      width: 100%;
    }
    background: #cacaca;
    border-radius: 8px;
  }
  .skill-per {
    height: 8px;
    background-color: ${({ theme }) => (theme === 'light' ? '#00b55c' : '#f05a48')};
    border-radius: 8px;
    width: ${({per})=> per+"%"};
    transition: 1s linear;
  }
`;

export const IntroWrapper = styled.div`
  padding-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 22pt;
    color: ${({ theme }) => (theme === 'light' ? '#00e676' : '#ffa000')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 20pt;
    }

    h2 {
      margin-bottom: 1rem;
      font-size: 20pt;
      color: ${({ theme }) => (theme === 'light' ? '#373737' : '#e6e6e6')};
  
      @media (max-width: 960px) {
        mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
      }
  
      @media (max-width: 680px) {
        font-size: 16pt;
      }
    }}

  h3 {
    font-size: 20pt;
    color: ${({ theme }) => (theme === 'light' ? '#373737' : '#e6e6e6')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 16pt;
    }
  }

  h4 {
    font-size: 12pt;
    padding-bottom: 0.2rem;
    color: ${({ theme }) => (theme === 'light' ? '#373737' : '#e6e6e6')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 12pt;
    }
  }

  p {
    margin-bottom: 0rem;
    // font-size: 14pt;
    font-size: ${({ language }) => (language === 'en' ? '14pt' : '12pt')};
    font-weight: normal;
    color: ${({ theme }) => (theme === 'light' ? '#373737' : '#e6e6e6')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 12pt;
    }
  }
`;


export const Paragraph = styled.div`
  margin-bottom: 1.5rem;
`;


export const Contact = styled.div`
margin-left: 1rem;
  p {
    // margin-bottom: 1rem;
    font-size: 14pt;
    font-weight: normal;
    color: ${({ theme }) => (theme === 'light' ? '#373737' : '#e6e6e6')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 12pt;
    }
  }
`;

import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { useIntl } from "gatsby-plugin-intl"
import { ThemeContext } from 'providers/ThemeProvider';
import { Header } from 'components/theme';
import { Container, AboutButton } from 'components/common';
import dev from 'assets/thumbnail/PC313107.jpg';
import { Wrapper, IntroWrapper, Details, Contact, Thumbnail, Paragraph } from './styles';

export const Intro = () => {
  const { theme } = useContext(ThemeContext);
  const intl = useIntl()

  return (
    <Wrapper>
      <Header />
      <IntroWrapper as={Container}>
        <Details theme={theme} language={intl.locale}>
          <h1>About me</h1>
          <h3>Ozora Ogino</h3>
          <h4>Full Stack Engineer</h4>
          <p>
            {intl.formatMessage({ id: "about-intro-1" })}
            {intl.formatMessage({ id: "about-intro-2" })}
            {intl.formatMessage({ id: "about-intro-3" })}
          </p>
          <Paragraph />
          <p>
            {intl.formatMessage({ id: "about-intro-4" })}
            {intl.formatMessage({ id: "about-intro-5" })}</p>
          <Paragraph />
          <p>{intl.formatMessage({ id: "about-intro-6" })}</p>
          <Paragraph />
          <Contact theme={theme}>
          <Link to="/contact"><AboutButton theme={theme}>{intl.formatMessage({ id: "get-in-touch" })}</AboutButton></Link>
          </Contact>
        </Details>
        <Thumbnail>
          <img src={dev} alt="I’m Ozora and I’m a fullstack engineer!" />
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  );
};

import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import { ThemeContext } from 'providers/ThemeProvider';
import { Header } from 'components/theme';
import { Container, Button } from 'components/common';
import dev from 'assets/thumbnail/PC313107.jpg';
import { Wrapper, IntroWrapper, Details, Skill } from './styles';
import styled from 'styled-components';
// import s from './scripts'



export const Skills = (props) => {
  const { theme } = useContext(ThemeContext);
  const intl = useIntl()

  return (
    <Wrapper>
      <IntroWrapper as={Container}>
        <Details>
          <h2>Skills</h2>
          <div class="skills">
            {
              props.skills.map((skill) => (
                <Skill theme={theme} per={skill.per}>
                  <div class="skill">
                    <div class="skill-name">{skill.name}</div>
                    <div class="skill-bar">
                      <div class="skill-per"></div>
                    </div>
                  </div>
                </Skill>
              )) }
          </div>
        </Details>
      </IntroWrapper>
    </Wrapper>
  );
};
